.InstaBot,
.RoundScreen {
  padding: 25px 25px;
  height: 100vh;
  background: rgba(255, 255, 255);
  font-family: Inter, system-ui !important;
  overflow-x: hidden !important;
}
h5 {
  margin: 1.25rem 0 0.5rem 0;
  font-weight: 700;
  color: #6415ff;
  text-decoration: underline;
  text-align: center;
}

.Rounds {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  color: #243e63;
  text-decoration: underline;
}
.BG_HEADING {
  text-align: center;
  transform: translateX(0) translateY(0) rotate(0) skewX(-12deg) skewY(0)
    scaleX(1) scaleY(1);
}
.logout-button {
  z-index: 100;
  transition: 0.1s ease-in-out;
  font-size: 40px;
  color: gray;
  outline: 0 !important;
  border: 0;
  /* opacity: 0.5; */
}
.logout-button:hover {
  color: #6415ff;
}
.InstaBot > h1 > span,
.BG_HEADING > span {
  padding: 0 1rem;
  background: #6415ff;
  text-align: center;
  color: rgba(255, 255, 255);
  font-size: 2.5rem;
  font-weight: 900;
  display: inline-block;
}
.overLaypop {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  text-align: center;
}
.overLaypop input {
  margin: 25px 0 !important;
}
.InstaBot p,
.RoundScreen p {
  text-align: center;
  color: rgba(113, 128, 150);
  font-weight: 500;
}
#cookieInput,
#postInput {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  outline: none;
  font-size: 0.875rem;
}

#OTP,
#postInput {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  outline: none;
  font-size: 0.875rem;
}

.ERRMSG {
  color: crimson !important;
  text-align: center;
}
.ActiveRound,
.disabledRound {
  border-radius: 9999px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 700;
  padding: 0.85rem 2.5rem;
  color: rgba(255, 255, 255);
  font-size: 0.875rem;
  background: linear-gradient(
    115deg,
    #6415ff,
    #7431ff,
    #8244ff,
    #8e56ff,
    #9a66ff
  );
}
.ActiveRound:hover {
  background: #9a66ff !important;
}
.RoundBtns {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.disabledRound {
  margin: 0 2%;
  opacity: 0.5;
  background: grey !important;
  cursor: not-allowed;
}
@media (max-width: 620px) {
  .InstaBot > h1 > span,
  .BG_HEADING > span {
    font-size: 1.5rem !important;
  }
  .RoundBtns {
    flex-direction: column;
  }
  .disabledRound {
    margin: 2% 0;
  }
  .Rounds {
    font-size: 1.5rem !important;
  }
  .ActiveRound,
  .disabledRound {
    padding: 0.75rem 2rem;
    font-size: 0.75rem;
  }
  .AvtaarIMGDIV {
    padding: 5px !important;
    border: 4px solid #8e56ff !important;
  }
  .AvDiv p {
    padding-left: 0rem !important;
    font-size: 1rem !important;
    text-align: center !important;
  }
}

#p {
  display: none;
}
.WaitNotice {
  position: absolute;
  /* top: 40%; */
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem !important;
}

.roundmsgcontent{
  float: left;
  font-size: 1rem !important;

    position: relative;
    text-align: justify !important;
}
.participantsList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: justify !important;
  align-items: center;
}
.AvDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  margin: 15px 0;
  text-align: justify !important;
  align-items: center;
  flex-wrap: wrap !important;
}
.AvDiv p {
  color: #333 !important;
  font-size: 1.2rem;
  padding-left: 2rem;
  overflow: auto;
  text-align: justify;

  width: 200px;
}
.AvtaarIMGDIV {
  padding: 10px;
  border: 10px solid #8e56ff;
  width: fit-content;
  border-radius: 1000px;
}
.AvtaarIMGDIV img {
  max-width: 75px !important;
}
.engaged:hover {
  background-color: #6415ff !important;
}
.engaged:visited {
  color: #9a66ff !important;
}

.HashLoaderClass {
  position: absolute !important;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);

  /* display:flex;
  justify-content: center;
  align-items: center; */
}
